<template>
    <Layout class="container" :panels="panels" style="background-color: #01132C;" :padding="20" :gutter="20" :loading="loading">
      
    </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import moment from 'moment'
import UTIL from '@/utils'
import {mapGetters} from 'vuex'
moment.locale('zh-cn')
export default {
  name: "HumanResource",
  components:{Layout},
  computed:{
    
    ...mapGetters('board',['config','loading','total_employee_count','formal_employee_count','retired_back_employee_count','trainee_employee_count','new_employee_count','out_employee_count','age_groupd','education_grouped','hr_plan_amount','actual_hrcount','actual_hrvalue','hr_contract']),
    panels(){
      return [{
        x:0.6,y:0.3,w:8,h:1,
        control:"PanelCaption",
        data:{
          name:"员工情况",
          sub:"宁波高专成本合约系统数字化平台 v2.0.0"
        }
      },{
        x:14.5,y:0.3,w:9.5,h:1.5,control:"PanelTime"
      },{
        x:0.5,y:1.5,w:2.8,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"员工总数",
          border:12,
          value:this.total_employee_count,
          toFixed:0,
          unit:"人" 
        }
      },{
        x:3.3,y:1.5,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"总部办公",
           border:12,
          value:this.current_contract_amount,
          unit:'人',
          toFixed:2,
        }
      },{
        x:3.3,y:2.7,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"正式员工",
           border:12,
          value:this.formal_employee_count,
          unit:'人',
          toFixed:2,
        }
      },{
        x:3.3,y:3.9,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"退休返聘",
           border:12,
          value:this.retired_back_employee_count,
          unit:'人',
          toFixed:2,
        }
      },{
        x:3.3,y:5.1,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"实习生",
           border:12,
          value:this.trainee_employee_count,
          unit:'人',
          toFixed:2,
        }
      },{
        x:3.3,y:6.3,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"今年入职",
           border:12,
          value:this.new_employee_count,
          unit:'人',
          toFixed:2,
        }
      },{
        x:3.3,y:7.5,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"今年离职",
           border:12,
          value:this.out_employee_count,
          unit:'人',
          toFixed:2,
        }
      },{
        x:6.5,y:1.5,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"2023项目计划投入",
           border:8,
          value:this.hr_plan_amount,
          unit:'人',
          toFixed:2,
        }
      },{
        x:9.5,y:1.5,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"2023项目实际投入",
           border:8,
          value:this.actual_hrcount,
          unit:'人',
          toFixed:2,
        }
      },{
        x:12.5,y:1.5,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"当前人均产值",
           border:8,
          value:this.actual_hrvalue,
          unit:'万元',
          toFixed:2,
        }
      },{
        x:15.5,y:1.5,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"人均合同余额",
           border:8,
          value:this.hr_contract,
          unit:'万元',
          toFixed:2,
        }
      },{
        x:0.5,y:2.7,w:2.8,h:2.8,
        control:"PanelDvChartPie",
        data:{
          name:"年龄分布",
           border:9,
          raw:this.age_groupd,
          toFixed:2,
        }
      },{
        x:0.5,y:5.5,w:2.8,h:2.8,
        control:"PanelDvChartPie",
        data:{
          name:"学历分布",
           border:9,
          raw:this.education_grouped,
          toFixed:2,
        }
      },{
        x:6.5,y:2.7,w:12,h:2.8,
        control:"PanelDvNumboard",
        data:{
          name:"建设管理事业部",
           border:8,
          data:this.config.raw,
          toFixed:2,
        }
      },{
        x:6.5,y:5.5,w:12,h:2.8,
        control:"PanelDvNumboard",
        data:{
          name:"房建监理事业部",
           border:8,
          data:this.config.raw,
          toFixed:2,
        }
      },{
        x:6.5,y:8.3,w:12,h:2.8,
        control:"PanelDvNumboard",
        data:{
          name:"市政监理事业部",
           border:8,
          data:this.config.raw,
          toFixed:2,
        }
      },{
        x:18.8,y:1.5,w:5.3,h:6,
        control:"PanelDvNumboard",
        data:{
          name:"合同临期",
           border:12,
          value:this.config.current_contract_amount,
          unit:'人',
          toFixed:2,
        }
      },]
    }
  },
}
</script>